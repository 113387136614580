import React from "react"
import { Container, Typography, Link } from "@mui/material"
import danielsilvia from "../images/danielsilvia.jpg"

const Om = () => {
  return (
    <Container className="container">
      <Typography variant="h1">Om Eftersmak</Typography>
      <Typography variant="h4">
        Här på eftersmak kan du läsa om upplevelser med en koppling till mat och
        dryck. Vi som driver bloggen är Daniel och Silvia. Här presenterar vi
        oss själva lite kort.
      </Typography>
      <p></p>
      <img
        src={danielsilvia}
        alt="Daniel och Silvia"
        placeholder="blurred"
        width={300}
        style={{ margin: 20, float: "right" }}
      />
      <Typography variant="h3">Daniel</Typography>
      <p>
        Jag är en långt gången öl-nörd som nu även börjat nörda ner mig i vinets
        underbara värld. Mitt intresse inom mat och dryck föddes ur mitt sen
        barnsben stora intresse för teknik och upplevelser. Jag köpte tidigt en
        Sous vide-maskin och började experimentera med smaker. Efter att ha
        provat att brygga egen öl ökade förståelsen avsevärt för vad som
        påverkar ölets egenskaper och jag kunde helt plötsligt börja analysera
        och logga vad jag drack. Efter att ha recenserat över 1000 unika
        ölsorter började det kännas som rutin, och det var så vinets värld
        öppnade sig med ändå mer finskaliga skillnader, speciellt eftersom
        dimensionen årgång och ålder tillkommer (öl är färskvara).
      </p>
      <Typography variant="h3">Silvia</Typography>
      <p>
        Jag har lång erfarenhet av att utbilda inom service, så därför lägger
        jag stor vikt i våra recensioner på just service och bemötande.
      </p>
      <Typography variant="h3">Hör av dig!</Typography>
      Vi vill gärna höra från dig, oavsett om det är konstruktiv feedback,
      hejjarop eller inbjudningar till vinprovning. Du når oss på{" "}
      <Link href={"mailto:kontakt@eftersmak.se"}>kontakt@eftersmak.se</Link>.
      Hör gärna av er med feedback, frågor eller tips på vad vi ska skriva om.
    </Container>
  )
}

Om.propTypes = {}

export default Om
