import React, { useEffect, useState } from "react"
import { Container, Divider } from "@mui/material"

import PostCard from "../components/postcard.js"
import LatestPost from "../components/latestPost.js"

import config from "../siteConfig.js"

import ContentfulClient from "../contentfulClient.js"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const Home = () => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    contentClient.getPosts(config.postsPerPage).then(posts => setPosts(posts))
  }, [])

  return (
    <Container className="container">
      {posts && posts.length > 0 && (
        <>
          <LatestPost post={posts[0]} />
          <Divider sx={{ mb: 2, mt: 2 }} />
        </>
      )}
      <section className="post-feed">
        {posts &&
          posts.map(post => (
            // The tag below includes the markup for each post - components/common/PostCard.js
            <PostCard key={post.sys.id} post={post} />
          ))}
      </section>
    </Container>
  )
}

Home.propTypes = {}

export default Home
