import * as React from "react"
import { Container, Typography } from "@mui/material"

const NotFoundPage = () => (
  <Container className="container">
    <Typography variant="h1">
      Sidan kunde inte hittas (404: Not Found)
    </Typography>
    <p>Kontrollera webbadressen och försök igen.</p>
  </Container>
)

export default NotFoundPage
