import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ThemeProvider } from "@mui/material"
import ReactGA from "react-ga4"

import Layout from "./components/layout.js"
import theme from "./theme.js"

import Home from "./pages/home.js"
import Artiklar from "./pages/artiklar.js"
import Article from "./pages/artiklar/article.js"
import Recensioner from "./pages/recensioner.js"
import Destinationer from "./pages/destinationer.js"
import Om from "./pages/om.js"
import Search from "./pages/search.js"
import Review from "./pages/recensioner/recension.js"
import Destination from "./pages/destinationer/destination.js"
import NotFoundPage from "./pages/404.js"

const App = () => {
  ReactGA.initialize("G-Q7YXBDW1V9")

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Layout isHome={true}>
          <Routes>
            <Route path="/artiklar" element={<Artiklar />} />
            <Route path="/artiklar/:id" element={<Article />} />
            <Route path="/recensioner" element={<Recensioner />} />
            <Route path="/recensioner/:id" element={<Review />} />
            <Route path="/destinationer" element={<Destinationer />} />
            <Route path="/destinationer/:id" element={<Destination />} />
            <Route path="/om" element={<Om />} />
            <Route path="/search/:type/:query" element={<Search />} />
            <Route path="/" exact element={<Home />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </ThemeProvider>
    </Router>
  )
}

App.propTypes = {}

export default App
