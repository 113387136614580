const config = {
  lang: "sv-SE",

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: `Eftersmak`, // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: `Married to food, wine, travel and each other.`, // This allows an alternative site description for meta data for pages.

  shareImageWidth: 1000, // Change to the width of your default share image
  shareImageHeight: 523, // Change to the height of your default share image

  title: "Eftersmak.se",
  shortTitle: `Eftersmak`, // Used for App manifest e.g. Mobile Home Screen
  siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: `#e9e9e9`, // Used for Offline Manifest
  themeColor: `#15171A`, // Used for Offline Manifest

  cover_image: "../images/sven-wilhelm-2cRXSWyMHA8-unsplash.jpg",

  codeinjection_styles: "",

  twitter: false,
  twitterUrl: "",
  instagram: true,
  instagramUrl: "https://www.instagram.com/eftersmak.se",
  facebook: true,
  facebookUrl: "https://www.facebook.com/eftersmak",

  mainNavigation: [
    {
      url: "/artiklar",
      key: "articles",
      name: "Artiklar",
    },
    {
      url: "/recensioner",
      key: "reviews",
      name: "Recensioner",
    },
    {
      url: "/destinationer",
      key: "destinations",
      name: "Destinationer",
    },
  ],
  secondaryNavigation: [
    {
      url: "/om",
      key: "about",
      name: "Om Eftersmak",
    },
  ],

  contentful: {
    space: "gdbjomnqcp2c",
    accessToken: "uVtFcFNI2PV3_3A4EnHBwjG_quAdPjHjIYIWkAPdCJM",
  },
}

export default config
