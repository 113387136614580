import React, { useEffect, useState } from "react"
import { Container, Typography } from "@mui/material"

import PostCard from "../components/postcard"
import config from "../siteConfig"

import ContentfulClient from "../contentfulClient"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const Destinationer = () => {
  const [destiations, setDestiations] = useState([])

  useEffect(() => {
    contentClient
      .getDestinations(100)
      .then(destiations => setDestiations(destiations.items))
  }, [])

  return (
    <Container className="container">
      <Typography variant="h1">Destinationer</Typography>
      <section className="post-feed">
        {destiations.map(post => (
          // The tag below includes the markup for each post - components/common/PostCard.js
          <PostCard key={post.sys.id} post={post} />
        ))}
      </section>
      <section className="content-googlemapframe" style={{ marginTop: 25 }}>
        <Typography variant="h2">Karta</Typography>
        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=13YtqCeS3SsOxi8R8G_nU2ObEmw2gXERR&ehbc=2E312F"></iframe>
      </section>
    </Container>
  )
}

Destinationer.propTypes = {}

export default Destinationer
