import React, { useEffect, useState } from "react"
import { Container, Typography } from "@mui/material"

import PostCard from "../components/postcard"

import config from "../siteConfig"

import ContentfulClient from "../contentfulClient"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const Artiklar = () => {
  const [articles, setArticles] = useState([])

  useEffect(() => {
    contentClient.getArticles(100).then(articles => setArticles(articles.items))
  }, [])

  return (
    <Container className="container">
      <Typography variant="h1">Artiklar</Typography>
      <section className="post-feed">
        {articles.map(post => (
          // The tag below includes the markup for each post - components/common/PostCard.js
          <PostCard key={post.sys.id} post={post} />
        ))}
      </section>
    </Container>
  )
}

Artiklar.propTypes = {}

export default Artiklar
