import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles';

const TextLogo = ({ text }) => {

    const theme = useTheme();
    
    const backgroundStyle = {
        backgroundColor: theme.palette.primary.main,
        padding: 25,
    }
    
    const brandTextStyle = {
        color: theme.palette.primary.light,
        margin: 0
    };

    return (
        <div style={backgroundStyle}>
            <h1 style={brandTextStyle}>{text}</h1>
        </div>
    );
};

TextLogo.propTypes = {
    text: PropTypes.string,
};

export default TextLogo;