import React from "react"
import PropTypes from "prop-types"
import { Button } from "@mui/material"

const Tags = ({ tags }) => {
  return tags.map(tag => (
    <span key={tag.sys.id}>
      <Button
        variant="text"
        size="medium"
        style={{
          padding: 0,
          marginRight: 3,
          minWidth: 0,
        }}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          history.push("/" + tag.sys.id)
        }}
      >
        #{tag.sys.id}
      </Button>
    </span>
  ))
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      sys: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ),
}

export default Tags
