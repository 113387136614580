import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Container, Rating, Typography, Box } from "@mui/material"

import config from "../../siteConfig.js"

import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import readingTimeHelper from "reading-time/lib/reading-time"

import ContentfulClient from "../../contentfulClient.js"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      // render the EMBEDDED_ASSET as you need
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.description}
          style={{
            marginBottom: 10,
            height: 450,
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      )
    },
  },
}

const Review = () => {
  let params = useParams()
  const [review, setReview] = useState(null)

  useEffect(() => {
    contentClient.getReview(params.id).then(article => setReview(article))
  }, [])

  useEffect(() => {
    document.title =
      config.shortTitle + " - " + (review ? review.fields.title : "Recension")
  }, [review])

  const readingTime = review
    ? `${Math.round(
        readingTimeHelper(documentToHtmlString(review.fields.body)).minutes,
      )} min läsning`
    : ""

  const ratingLabels = {
    1: "Rekommenderas inte",
    2: "Kommer inte tillbaka",
    3: "Helt ok",
    4: "Bra",
    5: "Superbra!",
  }

  return (
    <Container className="container">
      {review != null && (
        <article className="content">
          <header className="post-full-header">
            <h1 className="content-title">{review.fields.title}</h1>

            <h5>
              Av: {review.fields.author.fields.name}, Publicerad:{" "}
              {new Date(review.sys.createdAt).toLocaleDateString("sv-SE")},{" "}
              {readingTime}
            </h5>
          </header>
          {review.fields.featureImage ? (
            <figure className="post-feature-image">
              <img
                src={review.fields.featureImage.fields.file.url}
                alt={review.fields.title}
              />
            </figure>
          ) : null}
          <section className="post-full-content">
            {/* The main post content */}
            <section
              className="content-body load-external-scripts"
              /*dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(article.fields.body),
                  }}
                /*/
            >
              {documentToReactComponents(review.fields.body, renderOptions)}
            </section>
            <section>
              <Typography component="legend" variant="h4">
                Totalt betyg:
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="read-only"
                  value={review.fields.overallRating}
                  size="large"
                  readOnly
                  sx={{ fontSize: "4rem" }}
                />
                <Box sx={{ ml: 2 }}>
                  {ratingLabels[review.fields.overallRating]}
                </Box>
              </Box>
            </section>
          </section>
        </article>
      )}
    </Container>
  )
}

Review.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default Review
