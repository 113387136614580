import * as React from "react"
import { Link, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import Navigation from "./navigation.js"
import TextLogo from "./textlogo.js"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import config from "../siteConfig"

//import "./layout.css"
import "../index.css"

const Layout = ({ children, isHome, compactHeader }) => {
  let location = useLocation()
  const [isScrolled, setScrolled] = React.useState(false)
  const [isHomePage, setIsHomePage] = React.useState(true)

  React.useEffect(() => {
    setIsHomePage(location.pathname === "/")
  }, [location])
  React.useEffect(() => {
    // Adding the scroll listener
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // Removing listener
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // Handler when page is scrolled
  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  return (
    <>
      <div className="viewport">
        <div className="viewport-top">
          {/* The main header section on top of the screen */}
          <header className={`site-head ${isScrolled && "site-head-scrolled"}`}>
            <div className="container">
              <div className="site-mast">
                <div className="site-mast-left">
                  <Link to="/">
                    <TextLogo text="EFTERSMAK" />
                  </Link>
                </div>
                <div className="site-mast-right">
                  {config.twitter && (
                    <a
                      href={config.twitterUrl}
                      className="site-nav-item"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="site-nav-icon"
                        src="/images/icons/twitter.svg"
                        alt="Twitter"
                      />
                    </a>
                  )}
                  {config.instagram && (
                    <a
                      href={config.instagramUrl}
                      className="site-nav-item"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon
                        className="site-nav-icon"
                        style={{ width: 40, height: 40 }}
                      />
                    </a>
                  )}
                  {config.facebook && (
                    <a
                      href={config.facebookUrl}
                      className="site-nav-item"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon
                        className="site-nav-icon"
                        style={{ width: 40, height: 40 }}
                      />
                    </a>
                  )}
                  {/*<a className="site-nav-item" href={ `https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/` } target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/rss.svg" alt="RSS Feed" /></a>*/}
                </div>
              </div>
              {isHome ? (
                <div
                  className={`site-banner ${
                    (isScrolled || compactHeader || !isHomePage) &&
                    "site-banner-scrolled"
                  }`}
                ></div>
              ) : null}
              <nav className="site-nav">
                <div className="site-nav-left">
                  {/* The navigation items as setup in Ghost*/}
                  <Navigation data={config.mainNavigation} />
                </div>
                <div className="site-nav-right">
                  <Navigation data={config.secondaryNavigation} />
                </div>
              </nav>
            </div>
          </header>

          <main className="site-main">
            {/* All the main content gets inserted here, index.js, post.js */}
            {children}
          </main>
        </div>

        <div className="viewport-bottom">
          {/* The footer at the very bottom of the screen */}
          <footer className="site-foot">
            <div className="site-foot-nav container">
              <div className="site-foot-nav-left">
                <Link to="/">{config.title}</Link> © 2023
              </div>
              <div className="site-foot-nav-right">
                <Navigation
                  data={config.mainNavigation}
                  navClass="site-foot-nav-item"
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
  compactHeader: PropTypes.bool,
}

export default Layout
