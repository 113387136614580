import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Container } from "@mui/material"

import config from "../../siteConfig.js"

import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import readingTimeHelper from "reading-time/lib/reading-time"

import ContentfulClient from "../../contentfulClient.js"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      // render the EMBEDDED_ASSET as you need
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.description}
          style={{ marginBottom: 10 }}
        />
      )
    },
  },
}

const Destination = () => {
  let params = useParams()
  const [destination, setDestination] = useState(null)

  useEffect(() => {
    contentClient
      .getDestination(params.id)
      .then(destination => setDestination(destination))
  }, [])

  useEffect(() => {
    document.title =
      config.shortTitle +
      " - " +
      (destination ? destination.fields.title : "Destination")
  }, [destination])

  const readingTime = destination
    ? `${Math.round(
        readingTimeHelper(documentToHtmlString(destination.fields.body))
          .minutes,
      )} min läsning`
    : ""

  return (
    <Container className="container">
      {destination != null && (
        <article className="content">
          {destination.fields.featureImage ? (
            <figure className="post-feature-image">
              <img
                src={destination.fields.featureImage.fields.file.url}
                alt={destination.fields.title}
              />
            </figure>
          ) : null}
          <section className="post-full-content">
            <h1 className="content-title">{destination.fields.title}</h1>

            <h5>
              Av: {destination.fields.author.fields.name}, Publicerad:{" "}
              {new Date(destination.sys.createdAt).toLocaleDateString("sv-SE")},{" "}
              {readingTime}
            </h5>
            {/* The main post content */}
            <section
              className="content-body load-external-scripts"
              /*dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(article.fields.body),
                  }}
                /*/
            >
              {documentToReactComponents(
                destination.fields.body,
                renderOptions,
              )}
            </section>
            <section className="content-googlemapframe">
              {destination.fields.googleMapsEmbedLink && (
                <iframe src={destination.fields.googleMapsEmbedLink}></iframe>
              )}
            </section>
          </section>
        </article>
      )}
    </Container>
  )
}

Destination.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default Destination
