import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Container } from "@mui/material"

import config from "../../siteConfig.js"

import readingTimeHelper from "reading-time/lib/reading-time"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import ContentfulClient from "../../contentfulClient.js"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      // render the EMBEDDED_ASSET as you need
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          alt={node.data.target.fields.description}
          style={{
            marginBottom: 10,
            maxHeight: "450px",
            objectFit: "contain",
          }}
        />
      )
    },
  },
}

const Article = () => {
  const [article, setArticle] = useState(null)

  let params = useParams()
  useEffect(() => {
    contentClient.getArticle(params.id).then(article => setArticle(article))
  }, [])

  useEffect(() => {
    document.title =
      config.shortTitle + " - " + (article ? article.fields.title : "Artikel")
  }, [article])

  const readingTime = article
    ? `${Math.round(
        readingTimeHelper(documentToHtmlString(article.fields.body)).minutes,
      )} min läsning`
    : ""

  return (
    <Container className="container">
      {article != null && (
        <article className="content">
          <header className="post-full-header">
            <h1 className="content-title">{article.fields.title}</h1>

            <h5>
              Av: {article.fields.author.fields.name}, Publicerad:{" "}
              {new Date(article.sys.createdAt).toLocaleDateString("sv-SE")},{" "}
              {readingTime}
            </h5>
          </header>
          {article.fields.featureImage ? (
            <figure className="post-feature-image">
              <img
                src={article.fields.featureImage.fields.file.url}
                alt={article.fields.title}
              />
            </figure>
          ) : null}
          <section className="post-full-content">
            {/* The main post content */}
            <section
              className="content-body load-external-scripts"
              /*dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(article.fields.body),
                  }}
                /*/
            >
              {documentToReactComponents(article.fields.body, renderOptions)}
            </section>
            <section className="content-tags">
              Taggar:{" "}
              {article.metadata.tags.map(tag => {
                return (
                  <a href={`/search/tag/${tag.sys.id}`} key={tag.sys.id}>
                    {tag.sys.id}
                  </a>
                )
              })}
            </section>
          </section>
        </article>
      )}
    </Container>
  )
}

Article.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default Article
