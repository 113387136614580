import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            light: "#a8dadc",
            main: "#457b9d",
            dark: "#1d3557"
        },
        secondary: {
            main: "#e63946"
        }
    }
  });

export default theme;