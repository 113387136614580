import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Chip, Rating } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Tags from "./tags.js"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import readingTimeHelper from "reading-time/lib/reading-time"
import { typeUrlPrefix, typeName } from "../util.js"

const PostCard = ({ post }) => {
  const theme = useTheme()
  const readingTime = readingTimeHelper(documentToHtmlString(post.fields.body))

  const avatarColor = type => {
    switch (type) {
      case "article":
        return theme.palette.secondary.light
      case "destination":
        return theme.palette.primary.contrastText
      default:
        return theme.palette.primary.light
    }
  }

  const url = `/${typeUrlPrefix(post.sys.contentType.sys.id)}/${
    post.fields.slug
  }/`

  return (
    <Link to={url} className="post-card">
      <header className="post-card-header">
        {post.fields.featureImage && (
          <div
            className="post-card-image"
            style={{
              backgroundImage: `url(${post.fields.featureImage.fields.file.url})`,
            }}
          >
            {post.sys.contentType.sys.id == "review" && (
              <Rating
                className="post-card-rating"
                name="read-only"
                value={post.fields.overallRating}
                readOnly
              />
            )}
            <Chip
              className="post-card-type"
              label={typeName(post.sys.contentType.sys.id)}
              size="small"
              sx={{
                bgcolor: avatarColor(post.sys.contentType.sys.id),
                fontSize: 12,
                color: "#000",
              }}
            />
          </div>
        )}
        {post.metadata.tags && (
          <div className="post-card-tags">
            {" "}
            {<Tags tags={post.metadata.tags} />}
          </div>
        )}
        <h2 className="post-card-title">{post.fields.title}</h2>
      </header>
      <section className="post-card-excerpt">{post.fields.excerpt}</section>
      <footer className="post-card-footer">
        {post.fields.author && (
          <div className="post-card-footer-left">
            <div className="post-card-avatar">
              {post.fields.author.fields.profile_image ? (
                <img
                  className="author-profile-image"
                  src={post.fields.author.fields.profile_image.fields.file.url}
                  alt={post.fields.author.fields.name}
                />
              ) : (
                <img
                  className="default-avatar"
                  src="/images/icons/avatar.svg"
                  alt={post.fields.author.fields.name}
                />
              )}
            </div>
            <span>{post.fields.author.fields.name}</span>
          </div>
        )}
        <div className="post-card-footer-right">
          <div>
            {post.fields.featured && (
              <Chip
                style={{ marginRight: 10 }}
                label="Populär"
                size="small"
                variant="outlined"
              ></Chip>
            )}
            {Math.round(readingTime.minutes)} minuter läsning
          </div>
        </div>
      </footer>
    </Link>
  )
}

PostCard.propTypes = {
  post: PropTypes.shape({
    sys: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contentType: PropTypes.shape({
        sys: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      featureImage: PropTypes.shape({
        fields: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
      body: PropTypes.object,
      featured: PropTypes.bool,
      excerpt: PropTypes.string.isRequired,
      author: PropTypes.shape({
        fields: PropTypes.shape({
          name: PropTypes.string.isRequired,
          profile_image: PropTypes.shape({
            fields: PropTypes.shape({
              file: PropTypes.shape({
                url: PropTypes.string,
              }),
            }),
          }),
        }),
      }).isRequired,
      overallRating: PropTypes.number,
    }).isRequired,
    metadata: PropTypes.shape({
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          sys: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired,
}

export default PostCard
