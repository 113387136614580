import React, { useEffect, useState } from "react"
import { Container, Typography } from "@mui/material"

import PostCard from "../components/postcard"
import config from "../siteConfig"

import ContentfulClient from "../contentfulClient"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const Recensioner = () => {
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    contentClient.getReviews(100).then(reviews => setReviews(reviews.items))
  }, [])

  return (
    <Container className="container">
      <Typography variant="h1">Recensioner</Typography>
      <section className="post-feed">
        {reviews.map(post => (
          // The tag below includes the markup for each post - components/common/PostCard.js
          <PostCard key={post.sys.id} post={post} />
        ))}
      </section>
    </Container>
  )
}

Recensioner.propTypes = {}

export default Recensioner
