import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Container, Typography } from "@mui/material"
import ContentfulClient from "../contentfulClient.js"
import config from "../siteConfig.js"
import PostCard from "../components/postcard.js"

const contentClient = new ContentfulClient(
  config.contentful.space,
  config.contentful.accessToken,
)

const Search = () => {
  let params = useParams()
  const [results, setResults] = useState(undefined)

  useEffect(() => {
    contentClient
      .searchPosts(params.type, params.query, 100)
      .then(searchResult => setResults(searchResult.items))
  }, [])

  return (
    <Container className="container">
      <Typography variant="h1">Sökresultat</Typography>
      <section className="post-feed">
        {results ? (
          results.length > 0 ? (
            results.map(post => (
              // The tag below includes the markup for each post - components/common/PostCard.js
              <PostCard key={post.sys.id} post={post} />
            ))
          ) : (
            <p>Inga resultat hittades</p>
          )
        ) : (
          <p>Söker...</p>
        )}
      </section>
    </Container>
  )
}

export default Search
