import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { grey } from "@mui/material/colors"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import theme from "../theme.js"
import { typeUrlPrefix } from "../util.js"

const LatestPost = ({ post }) => {
  const url = `/${typeUrlPrefix(post.sys.contentType.sys.id)}/${
    post.fields.slug
  }/`
  return (
    <Link to={url} style={{ textDecoration: "none", color: "#000" }}>
      <section className="latest-post">
        <Box
          color="#fff"
          bgcolor={theme.palette.secondary.light}
          style={{ paddingRight: 15 }}
        >
          <NewReleasesIcon sx={{ mb: 0.5 }} fontSize="large" /> Nytt:{" "}
        </Box>
        <Box
          bgcolor={grey[200]}
          color="black"
          style={{ paddingLeft: 15, textDecoration: "none" }}
        >
          {post.fields.title} <ArrowForwardIosIcon sx={{ mb: 0.5 }} />
        </Box>
      </section>
    </Link>
  )
}

LatestPost.propTypes = {
  post: PropTypes.object,
}

export default LatestPost
