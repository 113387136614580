export const typeUrlPrefix = type => {
  switch (type) {
    case "article":
      return "artiklar"
    case "destination":
      return "destinationer"
    case "review":
      return "recensioner"
    default:
      return type
  }
}

export const typeName = type => {
  switch (type) {
    case "article":
      return "Artikel"
    case "destination":
      return "Destination"
    case "review":
      return "Recension"
    default:
      return type
  }
}
