const contentful = require("contentful")

export default class ContentfulClient {
  constructor(space, accessToken) {
    this.client = contentful.createClient({
      space,
      accessToken,
    })
  }

  async getPost(id) {
    return this.client.getEntry(id)
  }

  async getPosts(limit = 10) {
    const promiseArticles = this.client.getEntries({
      content_type: "article",
      include: 1,
      limit,
      order: "sys.createdAt",
    })
    const promiseReviews = this.client.getEntries({
      content_type: "review",
      include: 1,
      limit,
      order: "sys.createdAt",
    })
    const promiseDestinations = this.client.getEntries({
      content_type: "destination",
      include: 1,
      limit,
      order: "sys.createdAt",
    })

    const values = await Promise.allSettled([
      promiseArticles,
      promiseReviews,
      promiseDestinations,
    ])
    let resultArray = []
    for (const value of values) {
      if (value.status == "fulfilled")
        resultArray = resultArray.concat(value.value.items)
    }
    resultArray.sort((a, b) => {
      var dateA = new Date(a.sys.createdAt)
      if (a.fields.publishDate) dateA = new Date(a.fields.publishDate)
      var dateB = new Date(b.sys.createdAt)
      if (b.fields.publishDate) dateB = new Date(b.fields.publishDate)
      return dateB - dateA
    })
    return resultArray
  }

  async getArticles(limit = 10) {
    return this.client.getEntries({
      content_type: "article",
      include: 1,
      limit,
      order: "sys.createdAt",
    })
  }

  async getArticle(slug) {
    const articles = await this.client.getEntries({
      content_type: "article",
      "fields.slug[in]": slug,
      include: 1,
      limit: 1,
      order: "sys.createdAt",
    })

    if (articles.total > 0) return articles.items[0]
    return null
  }

  async getReviews(limit = 10) {
    return this.client.getEntries({
      content_type: "review",
      include: 1,
      limit,
      order: "sys.createdAt",
    })
  }

  async getReview(slug) {
    const reviews = await this.client.getEntries({
      content_type: "review",
      "fields.slug[in]": slug,
      include: 1,
      limit: 1,
      order: "sys.createdAt",
    })

    if (reviews.total > 0) return reviews.items[0]
    return null
  }

  async getDestinations(limit = 10) {
    return this.client.getEntries({
      content_type: "destination",
      include: 1,
      limit,
      order: "sys.createdAt",
    })
  }

  async getDestination(slug) {
    const destinations = await this.client.getEntries({
      content_type: "destination",
      "fields.slug[in]": slug,
      include: 1,
      limit: 1,
      order: "sys.createdAt",
    })

    if (destinations.total > 0) return destinations.items[0]
    return null
  }

  async searchPosts(type, query, limit = 10) {
    if (type == "label" || type == "tag") {
      return this.client.getEntries({
        "metadata.tags.sys.id[in]": query,
        include: 1,
        limit,
        order: "sys.createdAt",
      })
    } else {
      return null
    }
  }
}
